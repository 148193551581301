<template>
  <div class="item-top">
    <div class="imt-left">
      <div class="imt-cats" v-if="categoryList.length>0">
        <div style="padding: 10px 20px">
          <el-input v-model="skuName" clearable size="mini" placeholder="输入商品名称" style="width: 188px;"
                    @keyup.enter.native="toSearch"
                    class="filter-item"/>
          <el-input v-model="skuNo" clearable size="mini" placeholder="输入货号" style="width: 188px;margin-left: 5px"
                    @keyup.enter.native="toSearch"
                    class="filter-item"/>
          <el-button class="filter-item" style="margin-left: 5px" size="mini" type="success" icon="el-icon-search"
                     @click="toSearch">搜索
          </el-button>
          <el-button class="filter-item" style="margin-left: 5px" size="mini" type="warning" icon="el-icon-refresh-left"
                     @click="toReset">重置
          </el-button>
        </div>
        <el-tabs v-model="activeName" @tab-click="choiceCat" v-if="categoryList.length>0">
          <el-tab-pane :label="item.catName" :name="index.toString()"
                       v-for="(item,index) in categoryList"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="imt-cats" v-if="!categoryList.length>0">
        <span class="imt-title1">暂无分类</span>
      </div>
      <div class="imt-list">
        <div class="imt-items" v-if="specs.length>0">
          <div @click="addSpec(item)" class="imt-item" v-for="item in specs">
            <span class="imt-name">{{item.skuName}}</span>
            <span class="imt-price" style="color: #B3B1B1;font-size: 5px">No: {{item.skuNo}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imt-right">
      <div class="buy-item">
        <el-table
          :data="datas"
          @row-click="setRow"
          height="100%"
          highlight-current-row
          ref="singleTableRef"
          style="width: 100%">
          <el-table-column align="center" width="50">
            <template slot-scope="scope">
              <li @click.stop="delItem(scope.$index)" class="el-icon-delete-solid"
                  style="color:red;cursor: pointer;"></li>
            </template>
          </el-table-column>
          <el-table-column align="center" label="名称" property="itemName"/>
          <el-table-column align="center" label="编码" property="specNo"/>
          <el-table-column align="center" label="规格" property="spec"/>
          <el-table-column align="center" label="价格" property="price" width="100">
            <template slot-scope="scope">
              <el-input-number
                style="width: 90px"
                size="mini"
                v-model="scope.row.price"
                auto-complete="off"
                :precision="1"
                :controls="false"
                maxlength="6"
                @input="setPrice"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column align="center" label="销售" property="empName">
            <template slot-scope="scope">
              <div class="buy-xiaoshou" v-if="scope.row.emps">
                <div v-for="item in scope.row.emps" style="display: flex;flex-direction: row;margin-bottom: 3px">
                  <span style="width: 60px;line-height:28px;font-size: 13px">{{item.empName}}</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="imt-emp" v-if="exist">
        <div class="imt-gw" v-if="fEmps.length>0">
          <span class="gw-title">{{fName}}</span>
          <div class="emp-h">
            <div class="gw-emp" v-for="item in fEmps">
              <div class="emp-st" :class="{hui: item.check}" @click="setEmp(item,1)">
                <span class="name">{{item.nickName}}</span>

              </div>
            </div>
          </div>
        </div>
        <div class="imt-gw" v-if="sEmps.length>0">
          <span class="gw-title">{{sName}}</span>
          <div class="emp-h">
            <div class="gw-emp" v-for="item in sEmps">
              <div class="emp-st" :class="{hui: item.check}" @click="setEmp(item,2)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="imt-gw" v-if="tEmps.length>0">
          <span class="gw-title">{{tName}}</span>
          <div class="emp-h">
            <div class="gw-emp" v-for="item in tEmps">
              <div class="emp-st" :class="{hui: item.check}" @click="setEmp(item,2)">
                <span class="name">{{item.nickName}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--表单组件-->
    <el-dialog append-to-body :close-on-click-modal="false" :before-close="closeEvent" :visible.sync="visible"
               :title="title" width="600px">
      <div style="margin-top: -10px">
        <el-input placeholder="请输入规格" v-model="specName" class="input-with-select" size="small"
                  style="margin-top: -25px">
          <el-button slot="append" icon="el-icon-search" @click="sousuo"></el-button>
        </el-input>
        <el-table :data="spList" style="width: 100%" height="300" @row-click="addSku">
          <el-table-column prop="spec" label="规格"/>
          <el-table-column prop="cpStock" label="库存"/>
          <el-table-column prop="retailPrice" label="价格"/>
        </el-table>
      </div>
      <div style="margin-top: 8px;margin-left: 5px;display: flex;flex-direction: row;align-items: center">
        <span style="margin-right: 8px">编码:</span>
        <el-input v-model="specNo" size="mini" style="width: 300px"/>
      </div>
      <div style="margin-top: 10px;" v-if="memberId">
        <div style="display: flex;flex-direction: row;justify-items: center;line-height: 28px;margin-left: 5px">
          <span style="margin-right: 35px">服务项目</span>
          <el-button size="mini" type="text" @click="toItems">添加</el-button>
        </div>
        <div style="width: 500px;height: 200px;overflow: auto" v-if="dxItems.length>0">
          <div class="sps">
            <div class="spsitem" v-for="(item,index) in dxItems" :class="{'qudiao':index==0}">
              <div class="l" style="width: 45%">
                <span>{{item.composeName}} ( ¥{{item.tcPrice}} )</span>
              </div>
              <div class="z">
                <el-input-number v-model.number="item.total" :min="0" size="small"/>
              </div>
              <div class="r">
                <span @click="upIndex(index)">上移</span>
                <span @click="delDxItem(index)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="选择项目" :visible.sync="syVisible" width="45%">
      <div style="border: 1px solid #ddd;padding: 10px;margin-bottom: 5px" v-if="composes.length>0">
        <el-checkbox-group v-model="dxItemIds">
          <el-checkbox style="margin-top: 3px" v-for="item in composes" :label="item.id"
                       :key="item.id">
            {{item.composeName}}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer" style="margin-top: 10px">
                <el-button size="small" @click="syVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setSyChecked">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
  import {getToken} from "../../../utils/auth";

  export default {
    name: "billing-sku",
    components: {},
    data() {
      return {
        skuNo: '',
        skuName: '',
        activeName: '',
        specName: '',
        orderId: '',
        categoryList: [],
        datas: [],
        dataIndex: -1,
        catIndex: 0,
        catId: '',
        specs: [],
        total: 0,

        exist: false,
        fEmps: [],
        sEmps: [],
        tEmps: [],
        fName: '',
        sName: '',
        tName: '',

        memberCard: this.mc,

        title: '',
        visible: false,
        spList: [],
        skuItem: {},

        composes: [],
        dxItemIds: [],
        dxItems: [],
        syVisible: false,
        specNo:'',

      };
    },
    props: {
      mc: Object,
      specData: Array,
      specTotal: Number,
      memberId:String
    },

    async created() {
      this.datas = this.specData;
      this.specTotal = this.specTotal;
      await this.getCategoryList();
      await this.getSkuList();
      this.getEmpList();
    },

    methods: {

      toItems() {
        this.syVisible = true
        this.getDxtcList()
      },

      //获取套餐列表
      async getDxtcList() {
        let res = await this.$get("/sw/getSwComposeSingleList", {token: getToken()});
        this.composes = res.composes;
      },

      setSyChecked() {
        let arr = []
        this.composes.forEach(item => {
          this.dxItemIds.forEach(id => {
            if (id == item.id) {
              arr.push({
                id: item.id,
                miType: 3,
                itemId: item.itemId,
                composeName: item.composeName,
                tcPrice: item.tcPrice,
                total: 1,
              })
            }
          })
        })
        this.dxItems = arr
        this.syVisible=false
      },

      upIndex(index) {
        if (index == 0) {
          return;
        }
        let items = this.dxItems;
        let temp = items[index]
        items[index] = items[index - 1]
        items[index - 1] = temp;
        this.dxItems = [...items];
      },

      delDxItem(index) {
       let item = this.dxItems.splice(index, 1)
        let dxIndex = this.dxItemIds.indexOf(item.id)
        this.dxItemIds.splice(dxIndex, 1)
      },

      //获取项目分类列表
      async getCategoryList() {
        let data = {
          token: getToken(),
          catType: 1,
          specType: 'FW',
        }
        let res = await this.$get("/sw/getSwSkuCategoryList", data);
        this.categoryList = [...res.categoryList];
        if (res.categoryList.length > 0) {
          this.catId = res.categoryList[0].id
        }
      },
      async toSearch() {
        if (!this.skuName && !this.skuNo) {
          this.$message.error("请输入查询条件!")
          return;
        }
        let data = {
          skuName: this.skuName,
          skuNo: this.skuNo,
          isSell: 2,
          token: getToken(),
        }
        let res = await this.$get("/sw/getSwSkuSpecList", data);
        this.specs = res.specs;
      },
      toReset() {
        this.getSkuList()
      },
      //获取规格商品列表
      async getSkuList() {
        if (this.catId) {
          let res = await this.$get("/sw/getSwSkuSpecList", {catId: this.catId, isSell: 2, token: getToken()});
          this.specs = res.specs;
        }
      },

      sousuo() {
        var name = this.specName.replace(/\s+/g, "");
        let specs = []
        this.skuItem.skuSpecs.forEach(item => {
          var newStr = item.spec.replace(/\s+/g, "");
          let b = newStr.toUpperCase().match(name.toUpperCase())
          if (b) {
            specs.push(item)
          }
        })
        if (specs.length > 0) {
          this.spList = specs
        } else {
          this.spList = []
        }
      },

      closeEvent() {
        this.visible = false
        this.dxItems=[]
        this.dxItemIds=[]
        this.specNo=''
      },

      addSpec(item) {
        this.skuItem = item
        this.spList = JSON.parse(JSON.stringify(item.skuSpecs))
        this.title = item.skuName
        this.visible = true
      },

      //添加卖品
      addSku(row) {
        // if (!this.specNo){
        //   this.$message.error("请填写编码!")
        //   return;
        // }
        let item = {
          itemId: row.id,
          categoryId: this.skuItem.categoryId,
          storeDeptId: this.skuItem.storeDeptId,
          itemName: this.title,
          cmnVal: this.skuItem.cmnVal,
          fdCmnVal: this.skuItem.fdCmnVal,
          cost: row.cost,
          price: row.retailPrice,
          oglPrice: row.retailPrice,
          spec: row.spec,
          itemSign: 0,
          emps: [],
          dxItems:this.dxItems,
          specNo:this.specNo,
        }
        this.datas.push(item)
        this.dataIndex = this.datas.length - 1
        this.setEmpStatus(this.datas[this.dataIndex])
        this.$refs.singleTableRef.setCurrentRow(this.datas[this.dataIndex], true);
        var to = parseFloat(this.total) + parseFloat(row.retailPrice);
        this.total = to.toFixed(1)
        this.$emit('setSpecs', this.datas, this.total)
        this.visible = false
        this.dxItems=[]
        this.dxItemIds=[]
        this.specNo=''
      },


      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
        this.fName = res.fName;
        this.sName = res.sName;
        this.tName = res.tName;
        this.exist = res.exist;
      },

      //移除已选项目
      delItem(index) {
        let item = this.datas[index];
        this.datas.splice(index, 1);
        var to = parseFloat(this.total) - parseFloat(item.price);
        this.total = to.toFixed(1)
        this.$emit('setSpecs', this.datas, this.total);
        this.getEmpList();
        this.$refs.singleTableRef.setCurrentRow(-1)
      },
      //修改价格
      setPrice() {
        var total = 0
        this.datas.forEach(item => {
          total = parseFloat(total) + parseFloat(item.price)
        })
        this.total = total.toFixed(1)
        this.$emit('setSpecs', this.datas, this.total);
        this.$refs.singleTableRef.setCurrentRow(-1)
      },

      choiceCat() {
        this.catIndex = this.activeName;
        this.catId = this.categoryList[this.activeName].id;
        this.getSkuList()
      },

      // //计算总金额
      // calculation(total) {
      //   var price = 0
      //   this.datas.forEach(item => {
      //     if (item.price) {
      //       var to = parseFloat(price) + parseFloat(item.price)
      //       price = to.toFixed(1)
      //     }
      //   })
      //   this.total = price
      //   this.$emit('setSkus', this.datas, this.total);
      // },


      setSkusPrice(item) {
        this.memberCard = item
        var price = 0
        var costPrice = item.costPrice;
        this.datas.forEach(item => {
          //计算会员价
          if (this.memberCard.id && this.memberCard.skuDiscount > 0) {
            item.price = this.calculationPrice(item.oglPrice, this.memberCard.skuDiscount)
          }
          price = parseInt(price) + parseInt(item.price)
        })
        this.total = price
        this.$emit('setSkus', this.datas, this.total);
      },

      setSkuList(items) {
        this.datas = items
      },
      setMemberCard(item) {
        this.memberCard = item
      },

      setRow(item) {
        this.dataIndex = this.datas.indexOf(item);
        this.setEmpStatus(item.emps)
      },

      //设置员工
      async setEmp(item, type) {
        if (this.dataIndex == -1) {
          return;
        }
        if (this.datas.length > 0) {
          var is = item.check;
          if (!is) {
            this.datas[this.dataIndex].emps.push({
              empId: item.id,
              empName: item.nickName,
              fdCmnVal: item.fdCmnVal,
              station: type,
            })
          } else {
            var emp = this.datas[this.dataIndex].emps.find(it => {
              if (it.empId == item.id) {
                return it;
              }
            })
            let index = this.datas[this.dataIndex].emps.indexOf(emp);
            this.datas[this.dataIndex].emps.splice(index, 1)
          }
          item.check = !is
        }
      },

      // 将选中的人状态更改为没选中
      setUnchecked() {
        this.changeEmpFalse(this.emps);
      },

      // 切换套餐更改员工选中状态
      setEmpStatus(emps) {
        this.changeEmpFalse(this.fEmps);
        this.changeEmpFalse(this.sEmps);
        this.changeEmpFalse(this.tEmps);
        for (var i = 0; i < emps.length; i++) {
          let emp = emps[i];
          if (emp.station == 1) {
            this.changeEmpTrue(this.fEmps, emp, true)
          }
          if (emp.station == 2) {
            this.changeEmpTrue(this.sEmps, emp, true)
          }
          if (emp.station == 3) {
            this.changeEmpTrue(this.tEmps, emp, true)
          }
        }
      },

      //更改状态
      changeEmpTrue(emps, emp, check) {
        emps.forEach(item => {
          if (emp.empId == item.id) {
            item.check = check
          }
        })
      },

      //更改状态
      changeEmpFalse(emps) {
        emps.forEach(item => {
          item.check = false
        })
      },
      //计算价格
      calculationPrice(num1, num2) {
        var num = parseInt(num1 * num2 / 10)
        return num;
      }
    },
  };
</script>


<style lang="scss" scoped>
  .item-top {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .imt-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
    overflow: hidden;
  }

  .imt-cats {
    border-bottom: #E6E6E6 solid 1px;
    overflow: hidden;
    padding: 0px 8px;
  }

  .imt-title {
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin: 0 5px;
  }

  .imt-title1 {
    display: block;
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
  }

  .activeCat {
    color: red;
    border-bottom: red solid 2px;
  }

  .imt-list {
    height: 90%;
    overflow: hidden; // 超出部分隐藏
    overflow-y: scroll; // 设置y轴方向的滚动条
  }

  .imt-items {
    width: 95%;
    padding: 0px 2%;
    display: flex;
    flex-flow: row wrap;
  }

  .imt-item {
    /*flex: 1;*/
    box-sizing: border-box;
    border: #DDDDDD solid 1px;
    width: 138px;
    height: 60px;
    padding: 4px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    /*width: calc((100% - 60px) / 5); // 我这里一行显示4个 所以是/4  一行显示几个就除以几*/
    /*min-width: calc((100% - 60px) / 5);*/
    /*max-width: calc((100% - 60px) / 5);*/

    /*&:nth-child(4n + 4) {*/
    /*  margin-right: 0;*/
    /*}*/
  }

  .imt-name {
    margin-top: 3px;
    line-height: 20px;
    margin-left: 5px;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imt-price {
    line-height: 20px;
    margin-left: 5px;
    color: red;
    font-size: 13px;
    text-align: center;
  }

  .imt-right {
    width: 41%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .buy-item {
    height: 65%;
    border-right: #F2F2F2 solid 5px;
    font-size: 11px;
  }

  .buy-xiaoshou {
    display: flex;
    flex-direction: column;
  }

  .imt-emp {
    height: 35%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .imt-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .emp-st {
    cursor: pointer;
  }

  .gw-emp .name {
    width: 90%;
    margin-left: 5px;
  }

  .gw-emp .hui {
    width: 90%;
    margin-left: 5px;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .bp-nr {
    display: flex;
    flex-direction: column;
  }

  .bp-sp {
    width: 100%;
    border: #C4C4C4 solid 1px;
    margin: 1px 0px;
    border-radius: 3px;
  }

  ::v-deep .el-table__body tr.current-row > td {
    color: #000000;
    background: #EEE9E9 !important;
  }

  .cg-emp {
    height: 30%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .cg-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 100%;
    cursor: pointer;
    padding-left: 8px;
  }

  .gw-emp .hui {
    width: 100%;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .sps {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
  }

  .spsitem {
    display: flex;
    flex-direction: row;
    border-bottom: gainsboro solid 1px;
    padding: 5px 0px;
    justify-content: center;
    align-items: center;
  }

  .spsitem .l {
    width: 40%;
    display: flex;
    flex-direction: column;
  }

  .spsitem .z {
    width: 40%;
  }

  .spsitem .r {
    width: 20%;
  }
  .spsitem .r span {
    color: #FEA587;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
  }

</style>
